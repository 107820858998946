import React from 'react'
import { StyleStepText, StyledPointer, StyledButton, StyleCtaTitle, StyledCtaText, StyledMediumCta, StyledCtasContainer, StyledTitle, StyledSubText, StyledCtaImage, StyledMobileSeperator } from './styles';

import { StyledModuleContainer, StyledContainer } from '@styles/global-components/_containers';

export default function MediumContentCtaListing({
    module
}) {
    return (
        <StyledModuleContainer
            $sectionOptions={module.sectionOptions}
            moduleSpacing={'md'}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            data-name="medium-content-cta-listing"
            isOverflowHidden={module.enablePointer}
        >
            <StyledContainer type={'inner'}>  
                {module.title && 
                    <StyledTitle
                        fontSize={{ mobile: 'lg', desktop: 'xl' }}
                    > 
                        {module.title}
                    </StyledTitle>
                }

                {module.textTop && 
                    <StyledSubText 
                        bodyFontOptions={{ mobile: 'sm', desktop: 'sm'}}
                        content={module.textTop }
                    />
                }
                        
                <StyledMobileSeperator />

                {module.signUpSteps && 
                    <StyledCtasContainer>
                        {module.signUpSteps.map((contentCta, i) => (
                            <>
                                <MediumContentCta 
                                    contentCta={contentCta}
                                    module={module}
                                    isOddItem={i % 2}
                                    key={contentCta.text + i + 'medium-content-cta'}
                                />
                                {i !== module.signUpSteps.length - 1 &&
                                    <StyledMobileSeperator />
                                }
                                
                            </>
                        ))}
                    </StyledCtasContainer>
                }
        
            </StyledContainer>       
        </StyledModuleContainer>
    )
}

export const MediumContentCta = ({ module, contentCta, isOddItem }) => {
    const { stepText, title, featuredImage, text, buttonLink } = contentCta;

    return (
        <StyledMediumCta
            $isOddItem={isOddItem}
        >
            <StyledCtaImage
                className="medium-cta-image-col" 
                imageFile={featuredImage.imageFile}
                alt={featuredImage.altText}
            />
            <div className="medium-cta-text-col">
                {title && 
                    <StyleStepText
                        dangerouslySetInnerHTML={{ __html: stepText }}
                    />
                }

                {title && 
                    <StyleCtaTitle
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                }

                {text && 
                    <StyledCtaText 
                        content={text}
                    />
                }
            </div>

            {buttonLink && 
                <div className="relative fluid">
                    <StyledButton 
                        href={buttonLink.url} 
                        buttonOptions={module.buttonOptions}
                        fontSize={'sm'} 
                        $reduceWidth={module.enablePointer}
                    >
                        {buttonLink.title}
                    </StyledButton>

                    {module.enablePointer &&
                        <StyledPointer 
                            $isOddItem={isOddItem}
                            color={module.pointerColor} 
                        />
                    }
                </div>       
            }

        </StyledMediumCta>
    )
}