import styled, { css } from 'styled-components';
import { theme } from '@theme';

import WrButton from '@commons/wr-button';
import WrPointer from '@commons/wr-pointer';
import WrImage from '@commons/wr-image';
import WysiwygContent from '@commons/wysiwyg-content';
import ModuleTitle from '@commons/module-title';

export const StyledTitle = styled(ModuleTitle)`
    font-weight: 800;
    text-align: center;
    margin: 0 0 .45em 0;
`

export const StyledSubText = styled(WysiwygContent)`
    text-align: center;
`

export const StyledMobileSeperator = styled.hr`
    width: 200px;
    border-bottom: 1px solid #c4c4c4;
    margin: 30px auto;

    ${theme.mediaQuerys.smUp} {
        display: none;
    }
`

export const StyledCtasContainer = styled.div`
    margin-bottom: 20px;

     ${theme.mediaQuerys.smUp} {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-top: 40px;
        margin-bottom: 40px;
    }
`

export const StyledMediumCta = styled.div`


    &:last-of-type {
        margin-bottom: 0;
    }

    ${theme.mediaQuerys.smUp} {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 60px;

        ${props => props.$isOddItem && css`
            flex-direction: row-reverse;
        `}
    }

    ${theme.mediaQuerys.lgUp} {
        margin-bottom: 80px;
    }


    .medium-cta-text-col {
        text-align: center;
        
        ${theme.mediaQuerys.smUp} {
            flex: 1;
            max-width: 400px;

            ${props => props.$isOddItem ? css`
                padding-right: 5%;
                text-align: right;
            `:css`
                padding-left: 5%;
                text-align: left;
            `}
        }
    }

    .medium-cta-image-col {

        ${theme.mediaQuerys.smUp} {
            width: 50%; 
        }
    }
`

export const StyledCtaImage = styled(WrImage)`
    width: 65%;
    margin: 0 auto 20px auto;
    max-width: 320px;

    ${theme.mediaQuerys.smUp} {
        margin-bottom: 30px;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-bottom: 40px;
    }
`

export const StyledCtaText = styled(WysiwygContent)`
    display: none;

    ${theme.mediaQuerys.smUp} {
        display: block;
        margin-bottom: 30px;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-bottom: 40px;
    }
`

const titleFontSizing = css`
    font-size: 2.2rem;
    line-height: 31px;
    font-weight: 800;

    ${theme.mediaQuerys.smUp} {
        font-size: 2.2rem;
        line-height: 35px;
    }

    ${theme.mediaQuerys.lgUp} {
        /* font-size: 3rem;
        line-height: 43px; */
        font-size: 2.5rem;
        line-height: 35px;
    }
`

export const StyleStepText = styled.div`
    ${titleFontSizing};
    text-decoration: underline;
    font-style: italic;
`

export const StyleCtaTitle = styled.h3`
    margin: 0 0 20px 0;
    ${titleFontSizing};
`


export const StyledButton = styled(WrButton)`
    width: 100%;
    margin: 0 auto;
    
    ${props => props.$reduceWidth && css`
        width: calc(100% - 60px);      
    `}

    ${theme.mediaQuerys.mdUp} {
        width: calc(100% - 80px);  
    }
`

export const StyledPointer = styled(WrPointer)`
    position: absolute;
    top: -85px;
    height: 100px; 

    ${props => props.$isOddItem ? css`
        left: -40px;
        transform: rotate(15deg) scaleX(-1);
    `:css`
        right: -40px;
        transform: rotate(-15deg);
    `}

    ${theme.mediaQuerys.mdUp} {
        top: -110px;
    }
`